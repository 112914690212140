import { originalJquery } from '~public/js/src/default/jquery.js'
import { loadPlugin } from '~public/js/src/default/jquery-serialize-json.js'

const $ = originalJquery

loadPlugin($)

// necessary for jquery plugins
window.jQuery = $
window.$ = $

const jQuery = $

export { jQuery, $ }
