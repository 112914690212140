import type { Locales } from '~types/env.d.ts'
import {
  createI18n,
  type IntlDateTimeFormat,
  type IntlNumberFormat,
  type LocaleMessages,
  type VueMessageType,
} from 'vue-i18n'
import type { MessageSchema } from '~types/types.d.ts'
import { appData } from '~src/globals/variables.ts'

export function localI18n(messages: Record<Locales, LocaleMessages<VueMessageType>>) {
  return setupI18n({ messages }).global
}

export const currentLocale: Locales = appData.app.locale.replace('_', '-') as Locales

export const setupI18n = (
  { messages }: { messages?: Record<Locales, LocaleMessages<VueMessageType>> } = {},
  locale?: Locales,
) =>
  createI18n<[MessageSchema], Locales>({
    warnHtmlInMessage: 'off',
    locale: locale ?? currentLocale,
    availableLocales: ['cs-CZ', 'de-AT', 'de-DE', 'en-GB', 'es-ES', 'fr-FR', 'pl-PL'],
    datetimeFormats,
    numberFormats,
    messages: messages as unknown as Record<Locales, MessageSchema>, // Normally, this should be a Record<Locales, LocaleMessages<VueMessageType>>
    pluralRules: {
      'de-AT': (choice: number) => (choice != 1 ? 1 : 0),
      'de-DE': (choice: number) => (choice != 1 ? 1 : 0),
      'cs-CZ': (choice: number) => (choice == 1 ? 0 : choice >= 2 && choice <= 4 ? 1 : 2),
      'de-CH': (choice: number) => (choice != 1 ? 1 : 0),
      'sk-SK': (choice: number) => (choice == 1 ? 0 : choice >= 2 && choice <= 4 ? 1 : 2),
      'hu-HU': (choice: number) => (choice != 1 ? 1 : 0),
      'bg-BG': (choice: number) => (choice != 1 ? 1 : 0),
      'pl-PL': (choice: number) =>
        choice == 1 ? 0 : choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 12 || choice % 100 > 14) ? 1 : 2,
      'ro-RO': (choice: number) => (choice != 1 ? 1 : 0),
      'fr-FR': (choice: number) => (choice > 1 ? 1 : 0),
      'es-ES': (choice: number) => (choice != 1 ? 1 : 0),
      'en-GB': (choice: number) => (choice != 1 ? 1 : 0),
    },
    fallbackLocale: 'de-AT',
    formatFallbackMessages: true,

    missingWarn: false, // Not found key...
    fallbackWarn: false, // Fall back to...
  })

const datetimeFormats: Record<Locales, IntlDateTimeFormat> = {
  'cs-CZ': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
  },
  'de-AT': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: { hour: 'numeric', minute: 'numeric', hour12: false },
  },
  'de-DE': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: { hour: 'numeric', minute: 'numeric', hour12: false },
  },
  'en-GB': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    },
    datetime: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    },
    time: { hour: 'numeric', minute: 'numeric', hour12: true },
  },
  'es-ES': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: { hour: 'numeric', minute: 'numeric', hour12: false },
  },
  'fr-FR': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: { hour: 'numeric', minute: 'numeric', hour12: false },
  },
  'pl-PL': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    },
    datetime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    time: { hour: 'numeric', minute: 'numeric', hour12: false },
  },
}

const numberFormats: Record<Locales, IntlNumberFormat> = {
  'cs-CZ': {
    localCurrency: {
      style: 'currency',
      currency: 'CZK',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
  'de-AT': {
    localCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
  'de-DE': {
    localCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
  'en-GB': {
    localCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
  'es-ES': {
    localCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
  'fr-FR': {
    localCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
  'pl-PL': {
    localCurrency: {
      style: 'currency',
      currency: 'PLN',
      notation: 'standard',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: true,
    },
  },
}
