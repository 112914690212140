import { $ } from '~src/globals/jquery.ts'

/**
 * DO NOT ADD ANYTHING TO THIS FILE. It serves to split custom.js into smaller pieces without breaking the code.
 */
export function round(value, decimals) {
  decimals = typeof decimals !== 'undefined' ? decimals : 2
  value = parseFloat(value)
  const val = value < 0 ? value * -1 : value
  const $ret = Number(Math.round(val + 'e' + decimals) + 'e-' + decimals)
  return value < 0 ? $ret * -1 : $ret
}

/**
 * Shows a toast if invoice positions are selected and page is going to leave
 */
export let leaveTargetUri = ''
export const onLeavePage = function (targetUri, event, linkElement) {
  if (linkElement) {
    const $linkElement = $(linkElement)
    const href = $linkElement.attr('href')
    const isHrefDefined = !!href

    if (!isHrefDefined) {
      return true
    }

    const skipModal = $linkElement.data('invoiceModalSkip') === true
    if (skipModal) {
      return true
    }

    const target = $linkElement.attr('target')
    if (target === '_blank') {
      return true
    }

    const isHrefHash = href === '#' || href.charAt(0) === '#'
    if (isHrefHash) {
      return true
    }
  }

  const hasInvoicePositions = (document.querySelector('.btn-invoice')?.disabled ?? true) === false
  if (!hasInvoicePositions) {
    return true
  }

  event.preventDefault()
  event.stopPropagation()
  leaveTargetUri = targetUri

  if (isDefault()) {
    $('#modal-leave-page').modal('show')
  }
  return false
}

function getPathPage() {
  return document.body.id
}

export function isGastro() {
  return getPathPage() === '/intern/cash-register/business/gastro/invoice-new'
}

export function isDefault() {
  return getPathPage() === '/intern/cash-register/invoice-new'
}

export function isPinRequired() {
  return getPathPage() === '/intern/access-denied'
}

export function isVoucher() {
  return getPathPage() === '/intern/cash-register/voucher/voucher'
}
