import {
  replayIntegration,
  init,
  setTag,
  captureException,
  captureMessage,
  setContext,
  getReplay,
} from '@sentry/browser'
import { appData, isSupportedBrowser } from '~src/globals/variables.ts'

export { captureException, captureMessage, setTag }

function getReplayIntegration() {
  try {
    return replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      mask: ['.sentry-mask', '.rePanel'],
      unmask: [
        '.sentry-unmask',
        '.toast-message',
        '.nav-label',
        '.checkbox-container',
        '.page-heading',
        'h3',
        '.title',
        '.status',
        '.text-heading',
        '.pagination-box',
        '.payment-type',
        '.v-list-item',
        '.navbar-right',
        '.input-daterange',
        '.date',
        '.navbar-default',
        '.billing-left-panel .footer',
        '.modal-footer',
        '.modal-header',
        '.btn-white',
        '.number',
        '.btn-primary',
        '.btn-group',
        '.v-card__title',
        '.v-btn',
        'i::before',
        'label',
        '.nav-tabs',
        'thead',
      ],
      networkDetailAllowUrls: [
        '/crm/graphql',
        '/api/intern/cash-register/invoice/log-card-terminal-transaction',
        '/api/intern/cash-register/invoice/add',
        '/api/intern/cash-register/gastro/get-tables',
        '/api/intern/cash-register/gastro/get-menu-modal-data',
        '/api/intern/cash-register/gastro/get-orders',
        '/api/intern/cash-register/gastro/send-order',
        '/api/intern/cash-register/gastro/get-articles',
        '/api/intern/cash-register/cashbook/search',
        '/api/intern/cash-register/statistics/by-date-range',
      ],
      maskFn: function (text) {
        try {
          const datePattern = /\b\d{2}\.\d{2}\.\d{4}\b/
          const timePattern = /\b\d{2}:\d{2}\b/
          if (datePattern.test(text) || timePattern.test(text)) {
            return text
          }
        } catch (e) {
          //
        }
        return text.replace(/[\S]/g, '*')
      },
      networkRequestHeaders: [],
      networkResponseHeaders: ['Toast'],
    })
  } catch (e) {
    // multiple replay instances not supported
    return getReplay()
  }
}

function isLocalEnvironment() {
  return appData.app.environment === 'local'
}

function isLocalAllowed() {
  return isLocalEnvironment() && import.meta.env.VITE_USE_SENTRY_LOCAL === 'true'
}

function isSentryAllowed() {
  if (isLocalEnvironment() && !isLocalAllowed()) {
    return false
  }

  return isSupportedBrowser
}

function getAllowedOrigins() {
  const origins: string[] = []

  if (isLocalEnvironment()) {
    origins.push('http://localhost:5173')
  }

  return [...origins, window.location.origin]
}

function getSampleRate() {
  if (!isSentryAllowed()) {
    return 0
  }

  return appData.app.sentry.pt ? 0.1 : 1
}

export let replay: ReturnType<typeof replayIntegration> | null

export function initialize() {
  if (!appData.app.sentry.dsn) {
    return
  }

  replay = getReplayIntegration() ?? null

  init({
    dsn: appData.app.sentry.dsn,
    debug: isLocalAllowed(),
    release: appData.app.version,
    environment: appData.app.environment,
    sampleRate: getSampleRate(),
    tunnel: '/sentry-tunnel',
    allowUrls: getAllowedOrigins(),
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Avast extension error
      '_avast_submit',
      'Permission denied to access property', // plugins error on firefox
      'unavailablePrinter is not defined', // see android #38, ios #44
      "Can't find variable: getAppTranslations", // see android #38, ios #44
      "Can't find variable: setMobileVersion", // see android #38, ios #44
      '$ is not defined', // see android #38, ios #44
      'is not valid JSON', // hc #2056
      'JSON Parse error: Unexpected identifier', // hc #2056
      'Non-Error exception captured', // see $.request
      'Non-Error promise rejection captured', // see $.request
      'sleeknotestaticcontent', // popup builder on login page
      'hasAttribute is not a function', // vuetify safari bug mixins/overlayable
      '$.request(...).then(...).catch(...).finally is not a function', // unsupported browser
      'Event `ErrorEvent` captured as exception with message `Script error.`', //sleeknote same origin
      'Event `ErrorEvent` captured as exception with message `Syntaxfehler`', //sleeknote same origin
      "null is not an object (evaluating 'a[vd]')", // google chart old browser
      '__AutoFillPopupClose__',
      'Multiple Sentry Session Replay instances are not supported', // php not supported
    ],
    denyUrls: [
      'gstatic',
      '/_nuxt/', // temp ignore build
      '/business/default/plugins', // temp ignore plugins
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    replaysSessionSampleRate: appData.app.sentry.rSessionRate,
    replaysOnErrorSampleRate: appData.app.sentry.rErrorRate,
    integrations: [...(replay ? [replay] : [])],
    initialScope: {
      tags: { logger: 'js' },
      user: { id: appData.custom.company?.companyId },
    },
  })
}

export function sentrySetContext(key: string, context: { [p: string]: unknown } | null) {
  setContext(key, context)
}
export function sentryCaptureException(exception: Error, context?: { [p: string]: unknown } | null) {
  if (context) {
    sentrySetContext('triggered', context)
  }

  captureException(exception)
}

// See https://github.com/mRaPGmbH/hellocash/issues/4357
export function expectDataTruthy(
  this: JQuery.AjaxSettings | undefined,
  data: unknown,
  textStatus: 'success' | 'notmodified' | 'nocontent' | 'notdefined' = 'notdefined',
  jqXHR: JQuery.jqXHR | Record<string, unknown> = {},
) {
  if (data) {
    return true
  }

  const context = {
    extra: {
      data,
      textStatus,
      request: {
        url: this?.url,
        method: this?.method,
        type: this?.type,
        data: this?.data,
      },
      response: {
        responseText: jqXHR.responseText,
        statusCode: jqXHR.status,
      },
    },
  }

  captureException(new Error('expect response data to be truthy'), context)
  return false
}
