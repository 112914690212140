import $ from 'jquery'
import { isDefault, onLeavePage } from '~public/js/src/utils.js'
import { toastError, toastSuccess, toastWarningCta, showToastNetworkError } from '~src/globals/toastr.ts'
import { captureMessage } from '~src/globals/sentry.ts'
import { locales } from '~src/jquery.ts'
import { getCookie } from '~src/utils.ts'
import { appData } from '~src/globals/variables.ts'

//Set CSRF protection for every ajax request
$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
  const token = getCookie('CSRF_TOKEN')
  if (token) {
    if (!options.crossDomain) {
      jqXHR.setRequestHeader('X-CSRF-TOKEN', token)
    }
  }
})

$(document).ajaxSuccess(function (event, response) {
  // to skip this handler set global: false on the ajax request

  try {
    // Redirect client if Location header is set on Ajax call
    jqueryRedirect(response)
    // Toast on version difference
    jqueryResponseCheckBackendVersion(response)
  } catch (e) {
    console.error(e)
  }

  // Get the toast message from the response or interpret the response status
  var message = jqueryResponseGetToastMessage(response)
  if (message) {
    toastSuccess(message)
  }
})

$(document).ajaxError(function (event, response, ajaxSettings, thrownError) {
  // to skip this handler set global: false on the ajax request

  try {
    // Shows offline toast on error
    registerToastOffline()
    // Redirect client if Location header is set on Ajax call
    jqueryRedirect(response)
    // Toast on version difference
    jqueryResponseCheckBackendVersion(response, 0, 15)
  } catch (e) {
    console.error(e)
  }

  // Get the toast message from the response or interpret the response status
  var message = jqueryResponseGetToastMessage(response)
  if (message) {
    toastError(message)
  }

  // maybe we should allow 422 errors behind evidently flag to see
  // where we can put frontend validation before sending to backend
  if (typeof Sentry !== 'undefined' && response.status !== 422 && response.status !== 409) {
    try {
      captureMessage(thrownError || response.statusText, {
        extra: {
          type: ajaxSettings.type,
          url: ajaxSettings.url,
          data: ajaxSettings.data,
          status: response.status,
          error: thrownError || response.statusText,
          response: response.responseText ? response.responseText.substring(0, 100) : '',
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
})

function registerToastOffline() {
  if (navigator.onLine) {
    return
  }

  showToastNetworkError()
}

function jqueryRedirect(response) {
  const location = response.getResponseHeader('Location')
  if (location) {
    setTimeout(function () {
      window.location.href = location
    }, 4000)
  }
}

let currentVersionToast = null
/**
 * Checks if the backend version is different from the frontend version and shows a toast message
 * @param response                  jQuery Response
 * @param onFirstBufferMinutes      First time user sees toast after a version difference (360 = 6 hours)
 * @param continuesBufferMinutes    Time between each toast notification (60 = 1 hour)
 */
function jqueryResponseCheckBackendVersion(response, onFirstBufferMinutes = 360, continuesBufferMinutes = 60) {
  const backendVersion = response.getResponseHeader('X-Backend-Version')
  const frontendVersion = appData.app.version
  const storageKey = 'backendVersionNotification'
  // Remove the previous notification immediately if the first buffer time is set to 0
  if (onFirstBufferMinutes === 0) {
    sessionStorage.removeItem(storageKey)
  }

  if (!backendVersion || !frontendVersion) {
    sessionStorage.removeItem(storageKey)
    return
  }

  if (backendVersion === frontendVersion) {
    sessionStorage.removeItem(storageKey)
    return
  }

  let notificationData = JSON.parse(sessionStorage.getItem(storageKey)) || {}
  const now = new Date()

  if (!notificationData.nextNotification) {
    // Adjust the buffer to show the toast immediately if the first buffer time is 0
    if (onFirstBufferMinutes === 0) {
      onFirstBufferMinutes -= 0.1
    }
    const bufferMs = onFirstBufferMinutes * 60 * 1000
    notificationData.nextNotification = new Date(now.getTime() + bufferMs)
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({
        lastNotification: null,
        nextNotification: notificationData.nextNotification.toISOString(),
      }),
    )
  }

  const notificationTime = new Date(notificationData.nextNotification)
  if (now > notificationTime) {
    notifyUser()
    // Schedule the next notification
    const bufferMs = continuesBufferMinutes * 60 * 1000
    const nextNotificationTime = new Date(now.getTime() + bufferMs)
    sessionStorage.setItem(
      storageKey,
      JSON.stringify({
        lastNotification: now.toISOString(),
        nextNotification: nextNotificationTime.toISOString(),
      }),
    )
  }
}

export function notifyUser() {
  // Check if a notification toast is already displayed to avoid duplicates
  if (currentVersionToast && currentVersionToast[0].style.display !== 'none') {
    return
  }

  const lang = locales()

  currentVersionToast = toastWarningCta(
    lang.updateFrontend,
    lang.newVersionAvailable,
    {
      text: lang.refreshNow,
      cta: async function (toast, event) {
        if (!isDefault()) {
          window.location.reload()
        }

        // invoice page has a logic, that if positions are added, an additional modal opens
        // when exiting the page
        if (onLeavePage(window.location.href, event, null)) {
          window.location.reload()
        }
        currentVersionToast = null
      },
      icon: 'fas fa-arrow-rotate-right',
    },
    { timeOut: 0, preventDuplicates: true },
  )
}

function jqueryResponseGetToastMessage(response) {
  if (response.status === 429) {
    return locales().tooManyRequests
  }

  var showToast = !!+response.getResponseHeader('toast')
  if (!showToast && response.status !== 422 && response.status !== 409) {
    return ''
  }

  var json = response.responseJSON || JSON.parse(response.responseText)
  var message = ''
  if (json.errors) {
    message = json.errors[Object.keys(json.errors)[0]][0]
  } else if (json.message || json.msg || json.error) {
    message = json.message || json.msg || json.error
  }

  return message
}

/**
 * old controller responses json string
 * new controller responses with json object
 * This function is a bridge between these two controllers
 * (NOTE) Frontend is normally not in sync with the backend update. If a controller update
 * has passed a certain time, parseJsonString can safely be removed on each ajax call,
 * because the object MUST be a json anyway. As told before this function is only a
 * bridge
 *
 * @returns {*}
 */
$.parseJsonString = function (jsonString) {
  if (typeof jsonString !== 'string') {
    return jsonString
  }

  if (jsonString === '') {
    throw new Error('JSON.parse on empty string is not possible')
  }

  return JSON.parse(jsonString)
}

/**
 * Wrapper for $.ajax to remove most of the boiler code on each request. Also converts $.ajax callback function to a promise
 * Callback `complete` is not needed, just use native promise `.finally()` which will trigger on exception and success
 *
 * When providing a `resourceId`, the request parameters are changed to fit the apiResource routes.
 * `POST` will be changed to `PUT` if provided and `/{id}` will be added to the route
 *
 * @example
 *  $.request('/api/intern/order-queries').then(function (response) { console.log(response) });
 *
 * @example
 *  // with options
 *  $.request({
 *      url: '/api/intern/order-queries',
 *      method: 'POST',
 *      json: { data: [] },
 *  }).then(function (response) { console.log(response) });
 *
 * @param {{ method?: 'GET' | 'DELETE' | 'PUT' | 'POST', resourceId?: number, url: string, data?: string, json?: object, loadingButton?: object, beforeSend?: function } | string } params
 * @returns {Promise<any>}
 */
$.request = function (params) {
  // use short writing with url only. useful for GET requests without body
  if (typeof params === 'string') {
    var paramUrl = params
  } else {
    var { method, url, data, json, withoutBaseUrl, resourceId, allowIdZero, loadingButton, beforeSend } = params
  }

  // initialize required request parameters
  var requestBaseUrl = typeof withoutBaseUrl !== 'undefined' && !!withoutBaseUrl ? '' : '/api/intern'
  var requestUrl = paramUrl || url
  var requestMethod = typeof method !== 'undefined' ? method : 'GET'
  var requestAllowIdZero = typeof allowIdZero !== 'undefined' ? allowIdZero : false
  var iconElement, icon, textElement, text

  if (
    typeof loadingButton !== 'undefined' &&
    typeof loadingButton.tagName !== 'undefined' &&
    loadingButton.tagName.toLowerCase() !== 'button'
  ) {
    loadingButton.button = function (status) {
      if (status === 'loading') {
        iconElement = loadingButton.querySelector('i')
        icon = iconElement.className
        textElement = loadingButton.querySelector('[data-button-text]')
        text = textElement.textContent

        loadingButton.classList.add('button-loading')
        loadingButton.disabled = true
        iconElement.className = 'fas fa-sync fa-spin'
        textElement.textContent = 'Loading...'
      } else if (status === 'reset') {
        iconElement.className = icon
        textElement.textContent = text
        loadingButton.disabled = false
        loadingButton.classList.remove('button-loading')
      }
    }
  }

  // return promise instead of $.ajax unknown
  return new Promise((resolve, reject) => {
    // data or json should be set, both is not possible
    if (data && json) {
      reject('$.request parameter json or data')
    }

    // mainly for get requests, a body is not defined
    var payload
    if (typeof data !== 'undefined') {
      payload = data
    }

    // if json key is defined, set content type header and stringify body
    var contentType
    if (typeof json !== 'undefined') {
      payload = JSON.stringify(json)
      contentType = 'application/json'
    }

    // if resourceId is available, change params to match apiResource endpoint
    if (typeof resourceId !== 'undefined' && (requestAllowIdZero || +resourceId > 0)) {
      requestUrl += '/' + resourceId
      if (requestMethod === 'POST') {
        requestMethod = 'PUT'
      }
    }

    // call jquery ajax
    void $.ajax({
      type: requestMethod,
      data: payload,
      contentType,
      url: requestBaseUrl + requestUrl,
      beforeSend: function () {
        if (typeof loadingButton !== 'undefined') {
          loadingButton.button('loading')
        }
        if (typeof beforeSend !== 'undefined') {
          beforeSend()
        }
      },
      complete: function () {
        if (typeof loadingButton !== 'undefined') {
          loadingButton.button('reset')
        }
      },
      success: function (json) {
        resolve(json)
      },
      error: function (error) {
        reject(error)
      },
    })
  })
}

/**
 * Barcode scanner plugin
 */
function registerPluginScan() {
  $.fn.scan = function (options) {
    var settings = $.extend(
      {
        delay: 800,
        endChar: [9, 13],
        scanFinished: null,
      },
      options || {},
    )

    this.scannerData = {
      vars: {
        timer: false,
        accumulatedString: '',
      },
    }

    this.on('keydown', (e) => {
      // if scan modal is closed with esc, scannerData is undefined because of detach and keydown is triggered
      if (!this.scannerData) {
        return
      }

      e.preventDefault()

      var keyCode = e.which || e.keyCode
      var objectVars = this.scannerData.vars
      var scanFinished = false

      if (objectVars.timer) {
        clearTimeout(objectVars.timer)
      }

      if (settings.endChar.indexOf(keyCode) !== -1) {
        scanFinished = true
      } else {
        objectVars.accumulatedString += decodeKeyEvent(e)
      }

      if (scanFinished) {
        if (objectVars.accumulatedString !== '') {
          typeof settings.scanFinished == 'function'
            ? settings.scanFinished.call(this, objectVars.accumulatedString)
            : (settings.scanFinished = null)
        }

        objectVars.accumulatedString = ''
      } else {
        objectVars.timer = setTimeout(function () {
          objectVars.accumulatedString = ''
        }, settings.delay)
      }
    })

    function decodeKeyEvent(object) {
      var keyCode = object.which || object.keyCode

      switch (true) {
        case keyCode >= 48 && keyCode <= 90:
        case keyCode >= 106 && keyCode <= 111:
          if (object.key !== undefined && object.key !== '') {
            return object.key
          }

          var sDecoded = String.fromCharCode(keyCode)

          switch (object.shiftKey) {
            case false:
              sDecoded = sDecoded.toLowerCase()
              break
            case true:
              sDecoded = sDecoded.toUpperCase()
              break
          }

          return sDecoded
        case keyCode >= 96 && keyCode <= 105:
          return keyCode - 96
      }

      return ''
    }

    this.detach = () => {
      this.scannerData = undefined
      this.off('keydown')
    }

    return this
  }
}
registerPluginScan()

export { $ as originalJquery }
