/**
 * Following variables are provided in php or webview
 */

// Always available
const appData = window.appData
// Only available in intern, when logged in (company information)
const companyData = appData.custom.company
const publicInvoiceData = appData.custom.publicInvoiceData
const invoiceDataString = appData.custom.invoiceDataString
const adyenData = appData.custom.adyenData

const AndroidApp = () => window.AndroidApp
const iOSApp = () => window.webkit?.messageHandlers?.interOp

export const isSupportedBrowser = appData.app.supportedBrowser

export { appData, companyData, publicInvoiceData, invoiceDataString, adyenData, AndroidApp, iOSApp }

// window\.(?!location|addEventListener|removeEventListener|innerWidth|getComputedStyle|dispatchEvent|scrollTo|height|width|print|open|pageYOffset|postMessage|prototype|setTimeout|XMLHttp|ActiveX|attachEvent)
declare global {
  interface Window {
    /**
     * Only available in public invoice
     * See resources/views/web/public/public_invoice.blade.php
     */
    publicInvoiceData?: Record<string, string>
    /**
     * Only available in intern, when creating a new invoice (?data in URL)
     * See view/intern/cash-register/invoice-new.php
     */
    invoiceDataString?: string
    /**
     * Only available on payment modal
     * See view/intern/company/accounting/tpl/payment.modal.php
     */
    adyenData?: { clientKey: string }

    AndroidApp?: {
      print: (data: string) => void
      setAppTranslations: (data: string) => void
      performCheckout: (
        amount: unknown,
        currency: string,
        data: string,
        cardTerminal?: unknown,
        cardTerminal2?: unknown,
      ) => void
      callBarcodeScanner: (data: string) => void
      setJavaScriptData: (data: string) => void
      callTCPPrinter: (data: string) => string
    }
    webkit?: {
      messageHandlers?: {
        interOp: {
          postMessage: (data: Record<string, string>) => void
        }
      }
    }
  }
}
