import toastr from 'toastr'
import { nanoid } from 'nanoid'
import '~public/css/src/toastr.css'
import { locales } from '~src/jquery.ts'

export function toastSuccess(message: string) {
  return toastr.success(message)
}

export function toastError(message: string) {
  return toastr.error(message)
}

export function toastWarning(message: string) {
  return toastr.warning(message)
}

export function toastWarningCta(message: string, title: string, cta: ToastCtaProps, options?: ToastrOptions) {
  return toastCta('warning', message, title, cta, options)
}

export function toastErrorCta(message: string, title: string, cta: ToastCtaProps, options?: ToastrOptions) {
  return toastCta('error', message, title, cta, options)
}

export function toastPending(title: string, message: string, options?: ToastrOptions) {
  return toastr.info(title, message, {
    progressBar: false,
    timeOut: 0,
    extendedTimeOut: 0,
    toastClass: 'toast toast-pending',
    preventDuplicates: true,
    ...(options ?? {}),
  })
}

const defaultOptions: Partial<ToastrOptions> = {
  closeButton: true,
  debug: false,
  progressBar: true,
  positionClass: 'toast-top-right',
  showDuration: 400,
  hideDuration: 250,
  closeDuration: 0,
  timeOut: 8000,
  extendedTimeOut: 30000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

// TimeOut is calculated based on the length of the message and title x charMultiplier
const charMultiplier = 50
// Min duration for toast that needs attention like warning or error
const toastAttentionMinDuration = 3500
// Min duration for toast
const toastMinDuration = 2000
// Max duration for toast. If toast is hovered, the extended timeout is used
const toastMaxDuration = 13000
// If toast is hovered, the extended timeout is used. Timeout x this multiplier
const toastExtendedMultiplier = 3
// Multiplier for the timeout based on the type of toast
const timeOutMultipliers = {
  success: 1,
  info: 1,
  warning: 2,
  pending: 2,
  warningCta: 3,
  errorCta: 3,
  error: 2,
} as const
const attentionTypes = ['warning', 'error', 'errorCta', 'warningCta']

export function setupToast() {
  toastr.options = defaultOptions

  setupCalculatedTimeout()

  addListenersOfflineOnline()
}

type ToastInstanceType = 'NetworkError' | 'ConnectionLost' | 'Connected'
const toastInstance: Partial<Record<ToastInstanceType, JQuery | null>> = {}

function hideToastInstance(toasts: ToastInstanceType[]) {
  toasts.forEach((toast) => {
    if (toastInstance[toast]?.is(':visible')) {
      toastr.clear(toastInstance[toast])
    }
  })
}

function showToastInstanceOnce(type: ToastInstanceType, showToast: () => JQuery) {
  if (toastInstance[type]?.is(':visible')) {
    return
  }
  toastInstance[type] = showToast()
}

export function showToastNetworkError() {
  showToastInstanceOnce('NetworkError', () => {
    const lang = locales()
    return toastr.warning(lang.requestFailedNetwork, lang.networkError, {
      iconClass: 'toast-warning fa-cloud-exclamation',
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: true,
      closeButton: true,
    })
  })
}

function showToastConnectionLost() {
  showToastInstanceOnce('ConnectionLost', () => {
    const lang = locales()
    return toastr.warning(lang.connectionLostDetails, lang.connectionLost, {
      iconClass: 'toast-warning fa-cloud-exclamation',
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: false,
      closeButton: true,
    })
  })
}

function showToastConnected() {
  hideToastInstance(['Connected', 'NetworkError', 'ConnectionLost'])

  showToastInstanceOnce('Connected', () => {
    const lang = locales()
    return toastr.success(lang.connectionRestoredDetails, lang.connectionRestored)
  })
}

function addListenersOfflineOnline() {
  // Prefetch the Font Awesome icon by creating an invisible element
  // This way the icon is cached and can be displayed when the user is offline
  const prefetchIcon = document.createElement('div')
  prefetchIcon.style.fontFamily = '"Font Awesome 6 Pro", serif'
  prefetchIcon.style.fontSize = '0'
  prefetchIcon.style.position = 'absolute'
  prefetchIcon.style.width = '0'
  prefetchIcon.style.height = '0'
  prefetchIcon.className = 'fa-regular fa-cloud-exclamation'
  document.body.appendChild(prefetchIcon)
  setTimeout(() => document.body.removeChild(prefetchIcon), 0)

  window.addEventListener('offline', () => {
    showToastConnectionLost()
  })

  window.addEventListener('online', () => {
    showToastConnected()
  })
}

function setupCalculatedTimeout() {
  Object.keys(timeOutMultipliers).forEach((toastFunction) => {
    // Store the original toast function
    const originalFunction = toastr[toastFunction as keyof Toastr] as (
      message: string | JQuery,
      title?: string,
      overrides?: ToastrOptions,
    ) => JQuery

    // Monkey patch the toast function with a new implementation
    // @ts-ignore
    toastr[toastFunction] = function (message: string, title?: string, options?: ToastrOptions) {
      if (!message) {
        throw new Error('Message is required')
      }

      // If timeOut is defined, skip timeOut calculation and call the original function
      if (typeof options?.timeOut !== 'undefined' || typeof options?.extendedTimeOut !== 'undefined') {
        return originalFunction(message, title, options)
      }

      const multiplier = timeOutMultipliers[toastFunction as keyof typeof timeOutMultipliers]

      const titleLength = title ? title.length : 0
      const timeOutBasedOnTextLength = (message.length + titleLength) * charMultiplier * multiplier

      const minDuration = attentionTypes.includes(toastFunction) ? toastAttentionMinDuration : toastMinDuration

      let clampedTimeout = Math.max(timeOutBasedOnTextLength, minDuration)
      clampedTimeout = Math.min(clampedTimeout, toastMaxDuration)

      return originalFunction(message, title, {
        ...options,
        timeOut: clampedTimeout,
        extendedTimeOut: clampedTimeout * toastExtendedMultiplier,
      })
    }
  })
}

const toastCta = (
  type: 'warning' | 'error',
  message: string,
  title: string,
  cta: ToastCtaProps,
  options?: ToastrOptions,
) => {
  const icon = cta.icon ? `<i class="tw-mr-1 fa-regular ${cta.icon}"></i>` : ''
  const id = nanoid()
  const tagName = cta.tagName || 'button'
  const classes = cta.classes || ''
  const attrs = cta.attrs
    ? Object.entries(cta.attrs || {})
        .map(([key, value]) => `${key}="${value}"`)
        .join(' ')
    : ''

  message += `<div class="tw-pt-2"><${tagName} ${attrs} id="toast-cta-${id}" class="btn btn-white btn-outline btn-sm ${classes}">${icon}${cta.text}</${tagName}></div>`

  const progressBar = !!options?.timeOut || !!options?.extendedTimeOut

  const toast = toastr[type](message, title, {
    progressBar,
    timeOut: options?.timeOut,
    extendedTimeOut: options?.extendedTimeOut,
    toastClass: 'toast toast-cta',
    preventDuplicates: true,
    ...(options ?? {}),
  })

  const ctaElement = document.getElementById(`toast-cta-${id}`)!

  const onClick = (event: Event) => {
    cta.cta(toast, event, ctaElement)
    if (!cta.preventClose && toast) {
      toast.remove()
      removeEventListener()
    }
  }
  const removeEventListener = () => {
    document.getElementById(`toast-cta-${id}`)?.removeEventListener('click', onClick)
  }

  document.getElementById(`toast-cta-${id}`)?.addEventListener('click', onClick)

  return toast
}

export type ToastCtaProps = {
  text: string
  icon: string
  cta: (toast: JQuery, event: Event, ctaElement: HTMLElement) => void
  preventClose?: boolean
  tagName?: string
  attrs?: Record<string, string>
  classes?: string
}

declare global {
  interface Window {
    toastr: Toastr
  }
}

window.toastr = toastr

export { toastr }
