import type { StrictMbscCalendarEvent } from '~types/calendar.ts'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '~types/types.d.ts'
import { ResponseError } from '~src/request.ts'
import { sentryCaptureException } from '~src/globals/sentry.ts'
import { ref } from 'vue'
import { toastError } from '~src/globals/toastr.ts'

export const isDevelopment = import.meta.env.MODE === 'development'

/**
 * Customers can have a broken localStorage (currently 39924 and 22694). This is a client problem and has nothing to do with us.
 * However, we receive Errors with using localStorage.getItem. The following function should only be used on places, where these customers trigger the error.
 */
export function localStorageGetItemRepair<T>(key: string, parsed?: true): T | null
export function localStorageGetItemRepair(key: string, parsed?: false): string | null
export function localStorageGetItemRepair<T>(key: string, parsed: boolean = true): T | null {
  try {
    const item = localStorage.getItem(key)
    if (parsed && item) {
      return JSON.parse(item) as T
    }

    return item as T
  } catch (e: unknown) {
    if (e instanceof Error && e.name === 'NS_ERROR_FILE_CORRUPTED') {
      // see firefox bug NS_ERROR_FILE_CORRUPTED https://github.com/mRaPGmbH/hellocash/issues/4349
      // There is no way to fix this, so we just clear the storage
      localStorage.clear()
      sessionStorage.clear()
    } else {
      sentryCaptureException(e as Error)
    }
  }

  return null
}

export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`) as string[]
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift() ?? null
  }

  return null
}

export function setCookie(name: string, value: string, days: number, secure: boolean): void {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }

  const secureFlag = secure ? '; secure' : ''

  document.cookie = `${name}=${value || ''}${expires}; path=/${secureFlag}`
}

/**
 * The function will only be executed after the last call in the given delay.
 */
export function debounce(func: (...args: unknown[]) => void, wait: number): (...args: unknown[]) => void {
  let timeout: number | undefined
  return function executedFunction(...args: unknown[]): void {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait) as unknown as number
  }
}

/**
 * The function will only be executed once in the given delay.
 *
 * If used in combination with date range picker,
 * the function will be executed twice if UNTIL date is selected before FROM date.
 */
export function throttle(func: (...args: unknown[]) => void, delay: number): (...args: unknown[]) => void {
  let inThrottle: boolean
  return function executedFunction(...args: unknown[]): void {
    if (!inThrottle) {
      func(...args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), delay)
    }
  }
}

// convert &lt; to <, &quot; to " etc. E.g. necessary to prevent double quotes in data attributes
export function decodeHtmlEntities(encodedString: string) {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = encodedString
  return textArea.value
}

interface CustomWindow extends Window {
  offer?: object
  payment?: object
  invoice?: object
  deliveryNote?: object
  voucher?: object
}

export const billAccessor = {
  get obj(): Record<string, object> {
    const objects = {
      offer: ['intern/offer'],
      payment: ['intern/payment'],
      invoice: ['intern/cash-register/invoice'],
      deliveryNote: ['intern/delivery-note'],
      voucher: ['intern/voucher'],
    }

    for (const [key, values] of Object.entries(objects) as [keyof CustomWindow, string[]][]) {
      const isUrlMatch = values.some((value) => window.location.href.includes(value))

      if (isUrlMatch && typeof (window as CustomWindow)[key] === 'object') {
        return (window as CustomWindow)[key]
      }
    }

    throw new Error(`Bill object not found`)
  },
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function absoluteDateDifferenceInSeconds(startDate: Date, endDate: Date): number {
  const MILLISECONDS_IN_A_SECOND = 1000
  const diffInMilliseconds = endDate.getTime() - startDate.getTime()
  return Math.abs(Math.round(diffInMilliseconds / MILLISECONDS_IN_A_SECOND))
}

// Helper function to adjust date and convert to ISO string without timezone offset
export function toLocalISOString(date: Date) {
  const localDate = new Date(date)
  localDate.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return localDate.toISOString().replace('T', ' ').substring(0, 19)
}
export function formatEventTime(event: StrictMbscCalendarEvent, onlyTime: boolean = false): string {
  const { d } = useI18n<{ message: MessageSchema }>()
  const startDate = new Date(event.start as Date)
  const endDate = new Date(event.end as Date)

  if (startDate.toDateString() === endDate.toDateString()) {
    const startTime = d(startDate, 'time')
    const endTime = d(endDate, 'time')
    if (onlyTime) {
      return `${startTime} - ${endTime}`
    }
    const eventDate = d(startDate, 'short')

    return `${eventDate}, ${startTime} - ${endTime}`
  } else {
    const startDateTime = d(startDate, 'datetime')
    const endDateTime = d(endDate, 'datetime')

    return `${startDateTime} - ${endDateTime}`
  }
}

// Helper function to extract HH:mm from a Date object
export function dateToLocalTimeString(date: Date): string {
  return date.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
}

// Helper function to covert a time string HH:mm to a valid Date object with today's date and the given time
export function timeToDate(time: string): Date {
  const [hours, minutes] = time.split(':').map(Number)
  const date = new Date()
  date.setHours(hours, minutes, 0, 0)
  return date
}

// helper to remove whitespace from dynamically generated strings
export function joinFiltered<T>(strings: T[], seperator: string = ' ', filter: (item: T) => boolean = Boolean): string {
  return strings.filter(filter).join(seperator)
}

/**
 * Because of laravel form validation, 422 errors are always the same
 */
export async function errorLaravel(error: Error | ResponseError<string>, errorKey: string = 'message') {
  if (error instanceof ResponseError) {
    if (error.response.status === 422) {
      return
    }
    toastError(error.body[errorKey] || error.message)
    return
  }

  toastError(error.message)
}

export function timeoutPoll(
  fn: () => Promise<boolean>,
  {
    interval = 60000,
    maxAttempts = 10,
    throwOnMaxAttempts = false,
  }: { interval: number; maxAttempts?: number; throwOnMaxAttempts?: boolean },
) {
  const attempts = ref(0)
  const isPolling = ref(false)

  async function doPolling() {
    do {
      try {
        isPolling.value = await fn()
      } catch (e) {
        console.error(e)
        isPolling.value = true
      }
      attempts.value += 1
      if (attempts.value >= maxAttempts) {
        if (throwOnMaxAttempts) {
          throw new Error('Max attempts reached')
        }
        break
      }
      await sleep(interval)
    } while (isPolling.value)
  }

  void doPolling()

  return { isPolling, attempts }
}

export function toggleLoading(element: HTMLElement, toggle: boolean) {
  if (toggle && !element.getAttribute('data-original-text')) {
    element.setAttribute('data-original-text', element.innerHTML)

    element.setAttribute('disabled', '')

    const spinnerOverlay = document.createElement('span')
    spinnerOverlay.classList.add('loading-spinner-overlay')
    Object.assign(spinnerOverlay.style, {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundColor: '#f8f8f8e3',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    })

    const spinner = document.createElement('i')
    spinner.classList.add('fas', 'fa-spinner', 'fa-spin')

    spinnerOverlay.appendChild(spinner)
    element.style.position = 'relative'
    element.appendChild(spinnerOverlay)
  }

  if (!toggle && element.getAttribute('data-original-text')) {
    element.innerHTML = element.getAttribute('data-original-text') || ''
    element.removeAttribute('data-original-text')

    element.removeAttribute('disabled')

    const spinnerOverlay = element.querySelector('.loading-spinner-overlay')
    if (spinnerOverlay) {
      spinnerOverlay.remove()
    }
  }
}
