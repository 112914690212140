import { $ } from '~src/globals/jquery.ts'
import { localI18n } from '~src/i18n.ts'

export function toggleModal(selector: string, show: boolean = true) {
  $(selector).modal(show ? 'show' : 'hide')
}

export function resetButton(selector: string) {
  $(selector).button('reset')
}

export function locales() {
  const { t } = localI18n(i18nMessages)

  return {
    connectionLostDetails: t(
      'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.',
    ),
    connectionLost: t('Verbindung verloren'),
    connectionRestoredDetails: t('Sie sind wieder online.'),
    connectionRestored: t('Verbindung wiederhergestellt'),
    networkError: t('Netzwerkfehler'),
    requestFailedNetwork: t('Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.'),
    tooManyRequests: t('Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.'),
    updateFrontend: t('Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.'),
    newVersionAvailable: t('Neue Version verfügbar!'),
    refreshNow: t('Jetzt aktualisieren'),
  }
}

// Generated by script
export const i18nMessages = {
  'de-AT': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.',
    'Neue Version verfügbar!': 'Neue Version verfügbar!',
    'Jetzt aktualisieren': 'Jetzt aktualisieren',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.',
    'Verbindung verloren': 'Verbindung verloren',
    'Sie sind wieder online.': 'Sie sind wieder online.',
    'Verbindung wiederhergestellt': 'Verbindung wiederhergestellt',
    Netzwerkfehler: 'Netzwerkfehler',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.',
  },
  'de-DE': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.',
    'Neue Version verfügbar!': 'Neue Version verfügbar!',
    'Jetzt aktualisieren': 'Jetzt aktualisieren',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.',
    'Verbindung verloren': 'Verbindung verloren',
    'Sie sind wieder online.': 'Sie sind wieder online.',
    'Verbindung wiederhergestellt': 'Verbindung wiederhergestellt',
    Netzwerkfehler: 'Netzwerkfehler',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.',
  },
  'cs-CZ': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Příliš mnoho požadavků najednou. Počkejte prosím chvíli a zkuste to znovu.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Obnovením stránky získáte přístup k nejnovějším funkcím.',
    'Neue Version verfügbar!': 'K dispozici je nová verze!',
    'Jetzt aktualisieren': 'Aktualizovat nyní',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash nefunguje v režimu offline. Prosím, připojte se k internetu.',
    'Verbindung verloren': 'Spojení ztraceno',
    'Sie sind wieder online.': 'Jste znovu online.',
    'Verbindung wiederhergestellt': 'Spojení obnoveno',
    Netzwerkfehler: 'Chyba sítě',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Žádost neúspěšná. Zkontrolujte prosím své internetové připojení.',
  },
  'fr-FR': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Trop de demandes simultanées. Veuillez patienter un moment et réessayer.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Actualiser la page pour accéder aux dernières fonctionnalités.',
    'Neue Version verfügbar!': 'Nouvelle version disponible !',
    'Jetzt aktualisieren': 'Mettre à jour maintenant',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash ne fonctionne pas en mode hors ligne. Veuillez établir une connexion Internet.',
    'Verbindung verloren': 'Connexion perdue',
    'Sie sind wieder online.': 'Vous êtes à nouveau en ligne.',
    'Verbindung wiederhergestellt': 'Connexion rétablie',
    Netzwerkfehler: 'Erreur réseau',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Échec de la demande. Veuillez vérifier votre connexion Internet.',
  },
  'en-GB': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Too many requests at once. Please wait a moment and try again.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Refresh the page to access the latest functions.',
    'Neue Version verfügbar!': 'New version available!',
    'Jetzt aktualisieren': 'Update now',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash does not work in offline mode. Please establish an internet connection.',
    'Verbindung verloren': 'Connection lost',
    'Sie sind wieder online.': 'You are back online.',
    'Verbindung wiederhergestellt': 'Connection restored',
    Netzwerkfehler: 'Network error',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Request failed. Please check your internet connection.',
  },
  'es-ES': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Demasiadas solicitudes a la vez. Espere un momento y vuelva a intentarlo.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Actualice la página para acceder a las últimas funciones.',
    'Neue Version verfügbar!': 'Nueva versión disponible',
    'Jetzt aktualisieren': 'Actualizar ahora',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash no funciona en modo offline. Por favor, establezca una conexión a internet.',
    'Verbindung verloren': 'Conexión perdida',
    'Sie sind wieder online.': 'Está de nuevo en línea.',
    'Verbindung wiederhergestellt': 'Conexión restablecida',
    Netzwerkfehler: 'Error de red',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Solicitud fallida. Por favor, compruebe su conexión a internet.',
  },
  'pl-PL': {
    'Zu viele Anfragen auf einmal. Bitte warten Sie einen Moment und versuchen Sie es erneut.':
      'Zbyt wiele zapytań jednocześnie. Poczekaj chwilę i spróbuj ponownie.',
    'Aktualisieren Sie die Seite um auf die neuesten Funktionen zuzugreifen.':
      'Odśwież stronę, aby uzyskać dostęp do najnowszych funkcji.',
    'Neue Version verfügbar!': 'Nowa wersja dostępna!',
    'Jetzt aktualisieren': 'Zaktualizuj teraz',
    'helloCash funktioniert nicht im Offline-Modus. Bitte stellen Sie eine Internetverbindung her.':
      'helloCash nie działa w trybie offline. Proszę nawiązać połączenie z internetem.',
    'Verbindung verloren': 'Utracono połączenie',
    'Sie sind wieder online.': 'Jesteś ponownie online.',
    'Verbindung wiederhergestellt': 'Połączenie przywrócone',
    Netzwerkfehler: 'Błąd sieci',
    'Anfrage fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.':
      'Żądanie nie powiodło się. Proszę sprawdzić połączenie z internetem.',
  },
}
// !generated by script
